.App {
  font-size: larger;
  text-align: center;
}
a {
  color: darkgoldenrod;
}
header {
  background-color: #706e6e;
  color: #fff;
  padding: 20px;
}

.App.light header {
  background-color: #fff;
  color: #333;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

h2 {
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
}

button {
  margin-left: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.App.light {
  background-color: #fff;
  color: #000;
}

.App.dark {
  background-color: #616161;
  color: white;
}

.App.light a {
  color:#000;
}

.theme-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

.theme-toggle-button:focus {
  outline: none;
}

button svg {
  width: 24px;
  height: 24px;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: inherit; 
  border-bottom: 1px solid #ccc;
}

.profile-image {
  width: 50px;  
  height: 50px; 
  border-radius: 50%; 
  object-fit: cover;  
}
.projects, #v-links hr{
  width: 70%;

}

#bio{
  font-size: xx-large;
}


#about {
  display: grid;
  gap: 25px;
  box-shadow: 1px 1px 5px rgba(106, 235, 67, 0.815);
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #222;
}
.project-screenshot {
  border-radius: 5px;
  transition: transform 0.2s;
}
.project-screenshot:hover {
  transform: scale(1.1);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: white;
  padding: 10px;
  border-radius: 8px;
  position: relative;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.modal-image {
  max-width: 90vw;
  max-height: 80vh;
  border-radius: 5px;
}